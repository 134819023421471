import config from '../config'

const excludedViews = [
  'class-streaming-session', 'wizard'
]

export default {
  data () {
    return {
      showHelpButton: false,
      isExcludedView: false,
      helpArticles: []
    }
  },
  methods: {
    isCrispLoaded () {
      return window.$crisp && window.$crisp.do
    },
    async loadCrispChat () {
      return new Promise((resolve, reject) => {
        const viewName = this.$route.name
        this.isExcludedView = excludedViews.includes(viewName)
        if (this.isExcludedView) {
          this.$nextTick(() => {
            this.hideChatButton()
            resolve(false)
          })
          return
        }
        if (this.isCrispLoaded()) {
          this.$nextTick(() => {
            this.showChatButton()
            this.checkHelpArticles()
            resolve(true)
          })
          return
        }
        window.$crisp = []
        window.CRISP_WEBSITE_ID = '87d744c8-5e05-4d41-881f-f2680bbbc973'
        window.CRISP_RUNTIME_CONFIG = { locale: this.getLangCode() }

        const script = window.document.createElement('script')
        script.async = true
        script.defer = true
        script.src = 'https://client.crisp.chat/l.js?build=' + config.BuildVersion
        script.onload = () => {
          this.showChatButton()
          resolve(true)
        }
        window.document.head.append(script)
      })
    },
    async bootCrisp () {
      await this.loadCrispChat()
      if (!this.accountDetails) {
        return
      }
      const identity = this.getSetting('CHAT_IDENTIFICATION')
      if (identity && identity.value && identity.value.email && identity.value.token) {
        const email = identity.value.email
        const signature = identity.value.token
        window.$crisp?.push(['set', 'user:email', [email, signature]])
      } else if (this.accountDetails.email) {
        window.$crisp?.push(['set', 'user:email', [this.accountDetails.email]])
      }
      if (this.accountDetails.phone_number && this.accountDetails.phone_prefix) {
        window.$crisp?.push(['set', 'user:phone', ['+' + this.accountDetails.phone_prefix + ' ' + this.accountDetails.phone_number]])
      }
      window.$crisp?.push(['set', 'user:nickname', [this.getFullName()]])
      window.$crisp?.push(['set', 'user:avatar', [this.accountDetails.avatar.lowres_url]])
      let companyName = this.getSettingString('COMPANY_NAME')
      if (companyName && companyName.toLowerCase().includes('kydemy')) {
        companyName = window.location.hostname
      }
      window.$crisp?.push(['set', 'user:company', [companyName]])
      window.$crisp?.push(['set', 'session:data', [[
        ['account_id', this.accountID],
        ['hostname', window.location.hostname],
        ['build', config.BuildVersion]
      ]]])
      window.$crisp?.push(['on', 'helpdesk:queried', (ev) => {
        this.helpArticles = ev?.results ?? []
        this.showHelpButton = this.isExcludedView !== true && this.helpArticles.length > 0
      }])
      window.showErrorMessage = this.showErrorMessage
    },
    resetCrisp () {
      window.CRISP_TOKEN_ID = null
      this.showHelpButton = false
      this.helpArticles = []
      window.$crisp?.push(['do', 'session:reset'])
    },
    openChat (message) {
      window.$crisp?.push(['do', 'chat:open'])
      if (message) {
        window.$crisp?.push(['do', 'message:send', ['text', message]])
      }
    },
    showErrorMessage (errMsg) {
      errMsg = `Error: ${errMsg}\nPage: ${this.$route.name}\nURL: ${window.location.toString()}`
      window.$crisp?.push(['do', 'message:show', ['text', this.$t('errors.message')]])
      window.$crisp?.push(['do', 'message:show', ['text', errMsg]])
      window.$crisp?.push(['do', 'message:show', ['text', this.$t('errors.tell_us_more')]])
    },
    closeChat () {
      window.$crisp?.push(['do', 'chat:close'])
    },
    toggleChat () {
      window.$crisp?.push(['do', 'chat:toggle'])
    },
    hideChatButton () {
      this.showHelpButton = false
      this.helpArticles = []
      window.$crisp?.push(['do', 'chat:hide'])
    },
    showChatButton () {
      window.$crisp?.push(['do', 'chat:show'])
    },
    showHelpArticles () {
      window.$crisp?.push(['do', 'helpdesk:search'])
    },
    checkHelpArticles () {
      if (window.document && window.document.title) {
        const query = window.document.title.toLowerCase().replaceAll('kydemy', '').replaceAll(' - ', '')
        window.$crisp?.push(['do', 'helpdesk:query', [query]])
      }
    }
  }
}
