import rest from './rest'
import Kolor from './kolor'
import './icons'
import Color from 'color'
import moment from 'moment'
import config from './config'
import ColorHash from 'color-hash'

const shajs = require('sha.js')
const regExpDecimalNumber = /\d(?=(\d{3})+\D)/g
const colorHasher = new ColorHash({ lightness: 0.5 })
const darkColorHasher = new ColorHash({ lightness: 0.35 })
const defaultNoColor = '#535dac'
const classColors = {}

export default {
  data () {
    return {
      simpleDateTimeFormat: 'YYYY-MM-DD HH:mm',
      isoDateFormat: 'YYYY-MM-DD',
      dateFormatShort: 'D MMM',
      dateFormat: 'D MMM \'YY',
      dateFormatMedium: 'D MMMM YYYY'
    }
  },
  computed: {
    dateTimeFormat () {
      if (this.is24hFormat()) {
        return 'ddd D, MMM YYYY - HH:mm'
      }
      return 'ddd D, MMM YYYY - h:mm A'
    },
    dateTimeShortFormat () {
      if (this.is24hFormat()) {
        return 'D, MMM, HH:mm'
      }
      return 'D, MMM, h:mm A'
    },
    dateTimeMidFormat () {
      if (this.is24hFormat()) {
        return 'ddd D, MMM, HH:mm'
      }
      return 'ddd D, MMM, h:mm A'
    },
    timeFormat () {
      if (this.is24hFormat()) {
        return 'HH:mm'
      }
      return 'h:mm A'
    },
    accountID () {
      return this.$store.getters.accountID
    },
    accountDetails () {
      if (this.$store.getters.hasAccountData) {
        return this.$store.getters.accountData
      }
      return null
    },
    avatarURL () {
      return this.$store.getters.avatarURL
    },
    fullName () {
      return this.$store.getters.fullName
    },
    isStudentOnlinePaymentsActive () {
      if (this.isOnlinePaymentsActive) {
        return this.accountDetails && (this.accountDetails.last4 === null || !this.isSettingEnabled('DISABLE_ONLINE_PAYMENTS_BANK_ACCOUNTS'))
      }
      return false
    },
    isOnlinePaymentsActive () {
      if (this.isSettingEnabled('ONLINE_PAYMENTS')) {
        return this.stripeIntegrated || this.paypalIntegrated
      }
      return false
    },
    stripeIntegrated () {
      const stripeClientID = this.getSetting('STRIPE_CLIENT_ID')
      return stripeClientID && stripeClientID.value !== null && stripeClientID.value.length > 0
    },
    paypalIntegrated () {
      const paypalSetting = this.getSetting('PAYPAL_INTEGRATION')
      return paypalSetting && paypalSetting.value !== null && paypalSetting.value.client_id && paypalSetting.value.client_id.length > 0
    },
    classViewName () {
      if (this.isEffectiveManager()) {
        return 'class-details'
      } else if (this.isEffectiveTeacher()) {
        return 'teacher-class'
      }
      return 'student-class'
    },
    eventViewName () {
      if (this.isEffectiveManager()) {
        return 'event-details'
      }
      return 'student-event'
    },
    landingPageName () {
      let viewName = 'login'
      if (this.isEffectiveManager()) {
        viewName = 'admin-overview'
      } else if (this.isEffectiveTeacher()) {
        viewName = 'teacher-landing'
      } else if (this.isEffectiveRelative()) {
        viewName = 'relative-landing'
      } else if (this.isEffectiveStudent()) {
        viewName = 'landing'
      } else if (this.isEffectiveSubscriber()) {
        viewName = 'subscriber-landing'
      }
      return viewName
    },
    isDevelopment () {
      return config.BuildMode === 'development'
    },
    instanceConfig () {
      return config
    }
  },
  beforeMount () {
    moment.locale(this.getLangCode())
  },
  methods: {
    initials (text) {
      const tokens = text.toUpperCase().split(/[\s.,\-()]/)
      let inititals = ''
      for (const token of tokens) {
        if (token.trim().length > 0) {
          inititals += token.trim().substr(0, 1)
        }
      }
      return inititals
    },
    trimName (text, maxLength) {
      if (!maxLength) {
        maxLength = 10
      }
      if (text && text.length > maxLength) {
        return text.substring(0, maxLength - 1).trim() + '…'
      }
      return text
    },
    clipString (str, maxWidth, ellipsisText) {
      if (!ellipsisText) {
        ellipsisText = '…'
      }
      if (str.length <= maxWidth) {
        return str
      }
      return str.substring(0, maxWidth, ellipsisText)
    },
    getFullName (member) {
      if (member) {
        return member.firstname + ' ' + member.lastname
      } else {
        return this.$store.getters.fullName
      }
    },
    getFullNameShort (member) {
      if (!member) {
        member = this.$store.getters.accountData
      }
      if (member && member.firstname && member.lastname) {
        return member.firstname + ' ' + member.lastname.substring(0, 1) + '.'
      }
      return ''
    },
    getThemeImage (imgType) {
      switch (imgType) {
        case 'logo':
          return config.API + '/images/logo'
        case 'logo_header':
          return config.API + '/images/logo_header'
        case 'background':
          return config.API + '/images/background'
      }
      return null
    },
    getImageURL (relURL) {
      const baseURL = config.API
      return baseURL + (relURL.indexOf('/') > 0 ? '/' + relURL : relURL)
    },
    getCurrencyFlagIcon (currencyCode) {
      if (!currencyCode) {
        currencyCode = this.getCurrencyCode()
      }
      if (currencyCode && currencyCode.length > 2) {
        return 'flag-icon-' + currencyCode.substring(0, 2).toLowerCase()
      }
      return ''
    },
    formatCurrencyVat (amount, currencyCode, vat) {
      let newAmount = amount
      if (vat && vat > 0) {
        newAmount = amount + (amount * vat / 100.0)
      }
      return this.formatCurrency(newAmount, currencyCode)
    },
    formatNumber (number, round) {
      return this.formatCurrencyNoSymbol(number, '', round)
    },
    formatCurrencyNoSymbol (amount, currencyCode, round) {
      let num = ''
      if (amount !== null) {
        let d = ','
        let t = '.'
        if (this.getLangCode() !== 'en') {
          d = '.'
          t = ','
        }
        num = round === true ? Math.round(amount).toFixed(0) : amount.toFixed(Math.max(0, ~~2))
        num = (t ? num.replace('.', t) : num).replace(regExpDecimalNumber, '$&' + (d || ','))
      }
      return num
    },
    formatCurrency (amount, currencyCode, round) {
      const num = this.formatCurrencyNoSymbol(amount, currencyCode, round)
      if (num.length > 0) {
        const symbol = this.getCurrencySymbol(currencyCode)
        if (this.getLangCode() === 'en') {
          return symbol + ' ' + num
        } else {
          return num + ' ' + symbol
        }
      }
      return num
    },
    getCurrencySymbol (currencyCode) {
      if (!currencyCode) {
        const setting = this.getSetting('CURRENCY')
        if (setting && setting.value) {
          currencyCode = setting.value
        } else {
          return ''
        }
      }
      let symbol = '€'
      switch (currencyCode.toLowerCase()) {
        case 'eur':
          symbol = '€'
          break
        case 'gbp':
          symbol = '£'
          break
        case 'usd':
        case 'ars':
        case 'cad':
        case 'aud':
        case 'mxn':
        case 'clp':
        case 'uyu':
          symbol = '$'
          break
        case 'brl':
          symbol = 'R$'
          break
        case 'sek':
          symbol = 'kr'
          break
        case 'crc':
          symbol = '₡'
          break
        case 'cop':
          symbol = '$'
          break
        case 'ves':
          symbol = 'Bs.'
          break
        case 'dop':
          symbol = 'RD$'
          break
        case 'pen':
          symbol = 'S/'
          break
        case 'tnd':
          symbol = 'DT'
          break
        case 'bob':
          symbol = 'Bs'
          break
        case 'pyg':
          symbol = '₲'
          break
        case 'czk':
          symbol = 'Kč'
          break
        case 'bhd':
          symbol = 'BD'
          break
        default:
          symbol = currencyCode.toUpperCase()
      }
      return symbol
    },
    postAxiosRequest (relUrl, data, extraHeaders, progressFnx) {
      return rest.postAxiosRequest(relUrl, data, extraHeaders, progressFnx)
    },
    getAxiosRequest (relUrl, options) {
      return rest.getAxiosRequest(relUrl, options)
    },
    getAxiosFileUrl (relUrl) {
      const apiSlug = '/api/'
      if (relUrl.includes('http') && relUrl.includes(apiSlug)) {
        const index = relUrl.indexOf(apiSlug)
        relUrl = relUrl.substr(index + apiSlug.length)
      }
      return relUrl
    },
    getAxiosFileBinary (relUrl) {
      return rest.getAxiosFileBinary(this.getAxiosFileUrl(relUrl))
    },
    getAxiosFile (relUrl, fileName) {
      return rest.getAxiosFile(this.getAxiosFileUrl(relUrl), fileName)
    },
    putAxiosRequest (relUrl, data) {
      return rest.putAxiosRequest(relUrl, data)
    },
    deleteAxiosRequest (relUrl) {
      return rest.deleteAxiosRequest(relUrl)
    },
    apiHead (relUrl, options) {
      return rest.apiHead(relUrl, options)
    },
    apiGet (relUrl, options) {
      return rest.apiGet(relUrl, options)
    },
    apiPost (relUrl, data, extraHeaders, progressFnx) {
      return rest.apiPost(relUrl, data, extraHeaders, progressFnx)
    },
    apiPut (relUrl, data) {
      return rest.apiPut(relUrl, data)
    },
    apiDelete (relUrl) {
      return rest.apiDelete(relUrl)
    },
    getHeaderLocationID (response) {
      if (response && response.headers && 'location' in response.headers) {
        const tokens = response.headers.location.split('/')
        let objID = tokens[tokens.length - 1]
        if (objID.length === 0) {
          objID = tokens[tokens.length - 2]
        }
        return parseInt(objID)
      }
      return 0
    },
    async apiTraceError (description, data, sessionUUID) {
      await this.apiTrace(description, data, sessionUUID, true)
    },
    async apiTrace (description, data, sessionUUID, isError) {
      let postURI = 'traces/'
      if (this.$route && this.$route.query && this.$route.query.token && this.$route.query.token.length > 0) {
        const token = this.$route.query.token
        const accountID = this.$route.query.account_id
        postURI += `?token=${token}&account_id=${accountID}`
      } else if (this.$route.params && this.$route.params.uuid) {
        const uuid = this.$route.params.uuid.trim()
        postURI += `?uuid=${uuid}`
      }
      const traceData = {
        account_id: this.accountID,
        component: this.$options.name,
        path: window.location.hash,
        description: description,
        data: data,
        session_uuid: sessionUUID,
        error: !!isError
      }
      const apiResponse = await this.apiPost(postURI, traceData)
      if (apiResponse.isError() && this.$sentry) {
        this.$sentry.captureException(apiResponse.error)
      }
    },
    extend (target) {
      if (target === null) {
        target = {}
      }
      const sources = [].slice.call(arguments, 1)
      sources.forEach(function (source) {
        for (const prop in source) {
          target[prop] = source[prop]
        }
      })
      return target
    },
    cloneObject (target, extraData) {
      const newObj = Object.assign({}, target)
      if (extraData) {
        return this.extend(newObj, extraData)
      }
      return newObj
    },
    jsonClone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    navigateToDefaultView () {
      let viewName = 'login'
      let url = null
      /* eslint no-console: "off" */
      console.log('NAVIGATE TO DEFAULT VIEW')
      if (this.$route.query.redirect !== undefined && this.$route.query.redirect.length > 0) {
        url = this.$route.query.redirect
      } else {
        const roles = this.$store.getters.accountRoles
        if (roles.indexOf('manager') >= 0 || roles.indexOf('admin') >= 0) {
          if (this.isSettingEnabled('SHOW_SETUP')) {
            viewName = 'wizard'
          } else {
            viewName = 'admin-overview'
          }
        } else {
          if (roles.indexOf('teacher') >= 0) {
            this.$store.dispatch('effectiveRole', 'teacher')
          } else if (roles.indexOf('relative') >= 0) {
            this.$store.dispatch('effectiveRole', 'relative')
          } else if (roles.indexOf('subscriber') >= 0) {
            this.$store.dispatch('effectiveRole', 'subscriber')
          } else {
            this.$store.dispatch('effectiveRole', 'student')
          }
          const accData = this.$store.getters.accountData
          if (accData.must_reset_password === true) {
            viewName = 'reset-password'
          } else if (roles.indexOf('teacher') >= 0) {
            viewName = 'teacher-landing'
          } else {
            viewName = 'landing'
          }
        }
      }
      if (url !== null) {
        this.$router.push(url).catch(() => { /* Nothing to do */
        })
      } else {
        this.$router.push({ name: viewName }).catch(() => { /* Nothing to do */
        })
      }
    },
    getLangCode () {
      if (this.$i18n && this.$i18n.locale) {
        return this.$i18n.locale.split('-')[0]
      }
    },
    getLocale () {
      if (this.$i18n && this.$i18n.locale) {
        return this.$i18n.locale
      }
    },
    isMondayFirst () {
      if (this.$i18n && this.$i18n.locale) {
        return (this.$i18n.locale.indexOf('en') < 0)
      }
    },
    getColors () {
      return Kolor.colors
    },
    getDarkColors () {
      return Kolor.darkColors
    },
    getLightColors () {
      return Kolor.ligthColors
    },
    getHexColor (text) {
      return this._getHexColorHasher(text, colorHasher)
    },
    getHexColorDark (text) {
      return this._getHexColorHasher(text, darkColorHasher)
    },
    _getHexColorHasher (text, hasher) {
      if (!text) {
        return defaultNoColor
      }
      if (typeof text !== 'string') {
        text = text.toString()
      }
      return text.length > 0 ? hasher.hex(text) : defaultNoColor
    },
    getColorTagData (code, text) {
      const color = this.getHexColor(code || text)
      return {
        color: color,
        isDarkColor: !this.isLightColor(color),
        caption: (text || code).trim()
      }
    },
    removeAccents (str) {
      if (str.normalize) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      }
      return str
    },
    hashSha256 (text) {
      return shajs('sha256').update(text).digest('base64')
    },
    getRgbaColor (text, alpha) {
      const color = colorHasher.rgb(text)
      return `rgba(${color[0]},${color[1]},${color[2]},${alpha.toFixed(2)})`
    },
    getRgbaColorHex (colorHex, alpha) {
      const color = Kolor.color.hex(colorHex).color
      return `rgba(${color[0]},${color[1]},${color[2]},${alpha.toFixed(2)})`
    },
    getClassColor (cls) {
      if (cls.color && cls.color.length > 0) {
        return cls.color
      } else if (!('private' in cls) || cls.private !== true) {
        const key = 'C' + cls.class_id
        if (!(key in classColors)) {
          classColors[key] = this.getHexColor(key)
        }
        return classColors[key]
      } else {
        return '#3f3131'
      }
    },
    stringUcFirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    formatDateNormal (str) {
      return moment(str).format('D MMMM, YYYY')
    },
    getDateFormat (str) {
      const mnt = moment(str)
      const today = moment()
      return mnt.year() !== today.year() ? this.dateFormat : this.dateFormatShort
    },
    formatDateShort (str) {
      const mnt = moment(str)
      return mnt.format(this.getDateFormat(mnt))
    },
    searchSettings (search) {
      return new Promise((resolve, reject) => {
        const self = this
        self.getAxiosRequest('config/settings/?search=' + encodeURIComponent(search)).then(response => {
          const settings = {}
          for (const index in response.data) {
            settings[response.data[index].code] = response.data[index]
          }
          resolve(settings)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getSettingString (code) {
      const setting = this.getSetting(code)
      if (setting && 'value' in setting && setting.value) {
        return setting.value.toString()
      }
      return null
    },
    getSettingInt (code) {
      const settingVal = this.getSettingString(code)
      if (settingVal !== null && settingVal.length > 0) {
        const intVal = parseInt(settingVal)
        if (!isNaN(intVal)) {
          return intVal
        }
      }
      return null
    },
    getSettingFloat (code) {
      const settingVal = this.getSettingString(code)
      if (settingVal !== null && settingVal.length > 0) {
        const floatVal = parseFloat(settingVal)
        if (!isNaN(floatVal)) {
          return floatVal
        }
      }
      return null
    },
    isSpain () {
      return this.getSettingInt('DEFAULT_PHONE_PREFIX') === 34
    },
    getSetting (code) {
      return this.$store.getters.getSetting(code)
    },
    async getPrivateSetting (settingID) {
      try {
        const response = await this.getAxiosRequest(`config/settings/${settingID}/`)
        return response.data
      } catch (e) {
        return null
      }
    },
    async getPrivateSettingString (settingID) {
      const setting = await this.getPrivateSetting(settingID)
      if (setting && setting.value) {
        return setting.value.toString()
      }
      return ''
    },
    isSettingEnabled (code) {
      if (code) {
        const setting = this.getSetting(code)
        if (code === 'REGISTRATIONS_PAYMENT_REQUIRED') {
          return setting !== null && setting.value !== null && setting.value !== false
        }
        if (setting && setting.value) {
          return setting.value === true
        }
      }
      return false
    },
    async isRegistrationEnabled () {
      if (this.$store.state.Configuration) {
        return this.$store.state.Configuration.registrations === true
      } else {
        const response = await this.getAxiosRequest('config/')
        return response && response.data && response.data.registrations && response.data.registrations === true
      }
    },
    is24hFormat () {
      switch (this.getLangCode()) {
        case 'es':
        case 'ca':
        case 'fr':
        case 'de':
          return true
        default:
          return false
      }
    },
    getLangIconCode (langCode) {
      let iconCode = ''
      switch (langCode) {
        case 'ca':
          iconCode = 'es-ct'
          break
        case 'en':
          iconCode = 'gb'
          break
        default:
          iconCode = langCode
      }
      return iconCode
    },
    getUsername () {
      const accData = this.$store.getters.accountData
      if (accData && accData.username) {
        return accData.username.toString()
      }
      return null
    },
    isKydemyAdmin () {
      if (this.isAdmin() && (this.getUsername() === 'kydemy')) {
        return true
      }
      return !!(this.accountDetails && this.accountDetails.email && this.accountDetails.email.includes('@kydemy.com'))
    },
    isAdmin () {
      return this.isRole('admin')
    },
    isStudent () {
      return this.isRole('student')
    },
    isRelative () {
      return this.isRole('relative')
    },
    isSubscriber () {
      return this.isRole('subscriber')
    },
    isManager () {
      return this.isRole('manager')
    },
    isTeacher () {
      return this.isRole('teacher')
    },
    isTeacherOrManager () {
      return this.isRole('teacher') || this.isRole('manager')
    },
    isEffectiveTeacherOrManager () {
      return ['teacher', 'manager', 'admin'].includes(this.$store.getters.effectiveRole)
    },
    isEffectiveTeacher () {
      return this.$store.getters.effectiveRole === 'teacher'
    },
    isEffectiveManager () {
      return this.$store.getters.effectiveRole === 'manager' || this.$store.getters.effectiveRole === 'admin'
    },
    isEffectiveStudent () {
      return this.$store.getters.effectiveRole === 'student'
    },
    isEffectiveRelative () {
      return this.$store.getters.effectiveRole === 'relative'
    },
    isEffectiveSubscriber () {
      return this.$store.getters.effectiveRole === 'subscriber'
    },
    isMultiRole () {
      let numRoles = 0
      if (this.isManager() || this.isAdmin()) {
        numRoles++
      }
      if (this.isTeacher()) {
        numRoles++
      }
      if (this.isStudent()) {
        numRoles++
      }
      if (this.isRelative()) {
        numRoles++
      }
      if (this.isSubscriber()) {
        numRoles++
      }
      return numRoles > 1
    },
    isRole (role) {
      const roles = this.$store.getters.accountRoles
      if (roles && roles.length > 0) {
        return roles.indexOf(role) >= 0
      }
      return false
    },
    isChrome () {
      const isChromium = window.chrome
      const winNav = window.navigator
      const vendorName = winNav.vendor
      const isOpera = typeof window.opr !== 'undefined'
      const isIEedge = winNav.userAgent.indexOf('Edge') > -1
      return isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false
    },
    isIOSSafari () {
      const ua = window.navigator.userAgent
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
      const webkit = !!ua.match(/WebKit/i)
      return iOS && webkit && !ua.match(/CriOS/i)
    },
    async reloadSettings () {
      await this.$store.dispatch('getSettings')
    },
    getDiscountData (disID) {
      if (this.$store.getters.hasDiscounts) {
        const disc = this.$store.getters.discounts.find(d => d.global_discount_id === disID)
        if (disc) {
          return disc
        }
      }
      return null
    },
    getDiscountValue (itemDiscount, numDec) {
      if (!numDec) {
        numDec = 2
      }
      if (itemDiscount && itemDiscount.value) {
        return itemDiscount.is_percentage ? itemDiscount.value.toFixed(numDec) + ' %' : this.formatCurrency(itemDiscount.value)
      }
    },
    getDiscountColor (disID) {
      const disc = this.getDiscountData(disID)
      if (disc && disc.color) {
        return '#' + disc.color
      }
      return null
    },
    getDiscountName (disID) {
      const disc = this.getDiscountData(disID)
      if (disc && disc.name) {
        return disc.name
      }
      return null
    },
    getDiscountCode (disID) {
      const disc = this.getDiscountData(disID)
      if (disc && disc.code) {
        return '#' + disc.code
      }
      return null
    },
    hasMembershipData (code) {
      if (this.$store.getters.hasMemberships) {
        if (Number.isInteger(code)) {
          return !!this.$store.getters.getMembershipByID(code)
        } else {
          return !!this.$store.getters.getMembershipByCode(code)
        }
      }
      return false
    },
    getMembershipData (code) {
      if (this.$store.getters.hasMemberships) {
        const memData = this.$store.getters.getMembershipData(code)
        if (memData) {
          return memData
        }
      }
      return null
    },
    getMembershipColor (code) {
      if (this.$store.getters.hasMemberships) {
        const mshpColor = this.$store.getters.getMembershipColor(code)
        if (mshpColor) {
          return mshpColor
        }
      }
      return null
    },
    getMembershipName (mshpID) {
      if (this.$store.getters.hasMemberships) {
        let mshpFnx = this.$store.getters.getMembershipByID
        if (!Number.isInteger(mshpID)) {
          mshpFnx = this.$store.getters.getMembershipByCode
        }
        const mshp = mshpFnx(mshpID)
        if (mshp && mshp.name) {
          return mshp.name
        }
      }
      return null
    },
    getMembershipCode (mshpID) {
      if (this.$store.getters.hasMemberships) {
        const code = this.$store.getters.getMembershipCode(mshpID)
        if (code) {
          return code
        }
      }
      return null
    },
    isLightColor (color) {
      if (color && typeof color === 'string') {
        if (color.length === 6) {
          color = '#' + color
        }
        return Color(color).luminosity() > 0.7
      }
      return false
    },
    getAverageColor (cols) {
      if (cols === null) {
        return '#CCCCCC'
      }
      let col = Color('#' + cols[0])
      if (cols.length > 1) {
        for (let index = 1; index < cols.length; index++) {
          col = col.mix(Color('#' + cols[index]))
        }
      }
      return col.hex()
    },
    getClassesIcon () {
      const platformType = this.getSetting('BUSINESS_TYPE')
      const pType = platformType && platformType.value ? platformType.value : 'default'
      switch (pType) {
        case 'art_academy':
          return 'paint-brush'
        case 'education':
          return 'graduation-cap'
        case 'martial_arts':
          return 'male'
        case 'other':
          return 'cube'
        default:
          return 'music'
      }
    },
    getRoleIcon (role) {
      switch (role) {
        case 'student':
          return 'user-graduate'
        case 'teacher':
          return 'user-tie'
        case 'manager':
          return 'user-cog'
        case 'admin':
          return 'user-astronaut'
        case 'relative':
          return 'user-friends'
        case 'subscriber':
          return 'house-user'
        case 'lead':
          return 'user-tag'
      }
    },
    getRoleColor (role) {
      switch (role) {
        case 'admin':
          return '#de425b'
        case 'manager':
          return '#E8743B'
        case 'teacher':
          return '#5899DA'
        case 'student':
          return '#19A979'
        case 'relative':
          return '#ee658c'
        case 'subscriber':
          return '#2a80b9'
        case 'lead':
          return '#8E44AD'
      }
    },
    getClassDetailsRoute (cls, classTab, queryParams) {
      const clsID = cls && cls.class_id ? cls.class_id : cls
      let routeName = 'class-details'
      if (this.isEffectiveTeacher()) {
        routeName = 'teacher-class'
      } else if (this.isEffectiveStudent() || this.isEffectiveRelative()) {
        routeName = 'student-class'
      } else if (this.isEffectiveSubscriber()) {
        routeName = 'subscriber-class'
      }
      if (!queryParams) {
        queryParams = {}
      }
      queryParams = this.cloneObject(queryParams, { tab: (classTab || 'details') })
      return {
        name: routeName,
        params: { id: clsID },
        query: queryParams
      }
    },
    getAccountDetailsRoute (member, memberTab) {
      let routeName = 'member-details'
      if (this.isEffectiveTeacher()) {
        routeName = 'teacher-student'
      } else if (this.isEffectiveStudent() || this.isEffectiveSubscriber()) {
        routeName = 'student-student'
      } else if (this.isEffectiveRelative()) {
        routeName = 'relative-student'
      }
      return {
        name: routeName,
        params: { id: (member.account_id | member.teacher_id) },
        query: { tab: (memberTab || 'details') }
      }
    },
    async updateAccountSetting (setting) {
      try {
        await this.putAxiosRequest(`accounts/${this.accountID}/settings/${setting.setting_id}/`, setting)
        await this.reloadSettings()
      } catch (_) {
      }
    },
    async deleteAccountSetting (setting) {
      try {
        await this.deleteAxiosRequest(`accounts/${this.accountID}/settings/${setting.setting_id}/`)
        await this.reloadSettings()
      } catch (_) {
      }
    },
    async updateConfigSetting (setting) {
      /* eslint no-console: "off" */
      console.log('UPDATE SETTING:', setting.code, setting.value)
      const apiResponse = await this.apiPut('config/settings/' + setting.setting_id + '/', setting)
      if (apiResponse.isOK()) {
        setting.saving = false
        await this.reloadSettings()
        return true
      } else if (apiResponse.isError()) {
        return apiResponse.error
      } else {
        return false
      }
    },
    getStaticAssetURL (relPath) {
      if (process.env.NODE_ENV === 'production') {
        return '/assets/' + relPath
      } else {
        return relPath
      }
    },
    accountHasAnyRole (member, roles) {
      if (!(roles instanceof Array)) {
        roles = [roles]
      }
      return roles.filter(val => member.roles.indexOf(val) >= 0).length > 0
    },
    getCurrencyCode () {
      const setting = this.getSetting('CURRENCY')
      if (setting && setting.value) {
        return setting.value
      } else {
        return null
      }
    },
    getCurrencyIcon (currencyCode) {
      if (!currencyCode) {
        currencyCode = this.getCurrencyCode()
        if (currencyCode === null) {
          return ''
        }
      }
      let symbol = 'euro-sign'
      switch (currencyCode.toLowerCase()) {
        case 'eur':
          symbol = 'euro-sign'
          break
        case 'gbp':
          symbol = 'pound-sign'
          break
        case 'usd':
        case 'ars':
        case 'cad':
        case 'aud':
        case 'mxn':
        case 'brl':
        case 'cop':
        case 'dop':
        case 'nzd':
        case 'uyu':
          symbol = 'dollar-sign'
          break
        case 'krp':
        case 'kpw':
          symbol = 'won-sign'
          break
        case 'try':
          symbol = 'lira-sign'
          break
        case 'byr':
        case 'rub':
          symbol = 'lira-sign'
          break
        case 'mur':
        case 'npr':
        case 'pkr':
        case 'inr':
          symbol = 'rupee-sign'
          break
        case 'jpy':
          symbol = 'yen-sign'
          break
        case 'pyg':
          symbol = 'guarani-sign'
          break
        case 'bob':
        case 'tnd':
        case 'czk':
        case 'bhd':
        default:
          symbol = 'money-bill'
      }
      return symbol
    },
    getContinentIcon (continentCode) {
      switch (continentCode) {
        case 'NA':
        case 'SA':
          return 'globe-americas'
        case 'EU':
          return 'globe-europe'
        case 'AF':
          return 'globe-africa'
        case 'OC':
        case 'AS':
          return 'globe-asia'
        default:
          return 'globe'
      }
    },
    isResponseOK (response) {
      return response && response.status && response.status >= 200 && response.status < 300
    },
    responseHasData (response) {
      return response && response.status && response.status === 200 && response.data
    },
    danceRole (role, useGender) {
      if (this.isSettingEnabled('COUPLES_ROLE_GENDER') || useGender === true) {
        return role.toLowerCase() === 'leader' ? this.$t('male') : this.$t('female')
      } else {
        return this.$t(role)
      }
    },
    hasEncryptionKey () {
      if (window.sessionStorage) {
        const key = window.sessionStorage.getItem('encryption_key')
        return key !== null && key.length === 44
      }
      return false
    },
    getEncryptionKey () {
      if (window.sessionStorage) {
        return window.sessionStorage.getItem('encryption_key')
      }
      return null
    },
    setEncryptionSessionKey (keyHash) {
      if (window.sessionStorage) {
        return window.sessionStorage.setItem('encryption_key', keyHash)
      }
      return null
    },
    async checkValidEncryptionKey () {
      const keyHash = this.getEncryptionKey()
      let keyOK = false
      try {
        const response = await this.getAxiosRequest(`bank-accounts/key/validate/?key=${encodeURIComponent(keyHash)}`)
        keyOK = response && response.status && response.status === 204
      } catch (_) {
        keyOK = false
      }
      return keyOK
    },
    isBankAccountsEnabled (baType) {
      const bankAccountType = this.getSettingString('BANK_ACCOUNTS')
      if (baType) {
        return baType.toLowerCase() === bankAccountType.toLowerCase()
      }
      return this.hasValue(bankAccountType)
    },
    logError (err) {
      if (!err) {
        return
      }
      if (this.$sentry) {
        this.$sentry.captureException(err)
      }
      /* eslint no-console: "off" */
      console.log('Unhandled ERROR', err)
    },
    getNullableBoolean (value) {
      if (value === null || value.length === 0) {
        return null
      }
      return value === true || value === 'true'
    },
    hasValue (value) {
      return !(value === undefined || value === null)
    },
    isEmpty (value) {
      if (!this.hasValue(value)) {
        return true
      }
      switch (typeof value) {
        case 'string':
          return value.length === 0
        case 'number':
          return value === 0
        case 'object':
          if (Array.isArray(value)) {
            return value.length === 0
          }
          return false
        default:
          return false
      }
    }
  }
}
